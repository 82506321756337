import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./views/Home.vue')
    },
    {
        path: '/praca',
        name: 'Praca',
        component: () => import('./views/Praca.vue')
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import('./views/Kontakt.vue')
    },
    {
        path: '/firmy',
        name: 'Firmy',
        component: () => import('./views/Firmy.vue')
    },
    {
        path: '/zewnetrze',
        name: 'Zewnetrze',
        component: () => import('./views/Zewnetrze.vue')
    },
    {
        path: '/wspolnoty',
        name: 'Wspolnoty',
        component: () => import('./views/Wspolnoty.vue')
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes 
  })

router.beforeEach((to, from, next) => {   
    return next();
});

export default router
<template>
  <div id="app">
    <b-navbar toggleable="md" > 
      <b-container>
          <b-navbar-brand href="/home" ><img src='./assets/logo.png' /></b-navbar-brand>
          
          <b-navbar-toggle target="nav-collapse" class='navbar-light'></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav >
              <b-nav-item to='/home' id='navbar1'>O FIRMIE</b-nav-item>     

              <b-nav-item-dropdown text="OFERTA">
                <b-dropdown-item to="/firmy">SPRZĄTANIE BIUR</b-dropdown-item>
                <b-dropdown-item to="/zewnetrze">SPRZĄTANIE TERENÓW ZEWNĘTRZNYCH</b-dropdown-item>
                <b-dropdown-item to="/wspolnoty">SPRZĄTANIE WSPÓLNOT MIESZKANIOWYCH</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item to='/praca'>PRACA</b-nav-item>

              <b-nav-item to='/kontakt'>KONTAKT</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
      </b-container>
    </b-navbar>

    <b-container>
      <b-row>     
        <b-col md='6' lg='9'>

          <b-carousel
            id="carousel"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="547"
            style="text-shadow: 1px 1px 2px #333;"
          >

            <b-carousel-slide 
              :img-src="office"
            ></b-carousel-slide>
      
            <b-carousel-slide :img-src="kosiara" v-bind="caroseul_props">
            </b-carousel-slide>

            <b-carousel-slide :img-src="domy"></b-carousel-slide>

            <b-carousel-slide :img-src="lopata"></b-carousel-slide>

          </b-carousel>
          
          <div class='content'>
            <router-view />
          </div>

        </b-col>

        <b-col md='4' lg='3'>
          <div class='oferta-col'>

            <b-card :img-src="office" img-alt="Card image" img-bottom @click='$router.push("/firmy")'>
              <b-card-text>
                SPRZĄTANIE BIUR
              </b-card-text>
            </b-card>

            <b-card :img-src="kosiara" img-alt="Card image" img-bottom @click='$router.push("/zewnetrze")'>
              <b-card-text>
                SPRZĄTANIE TERENÓW ZEWNĘTRZNYCH
              </b-card-text>
            </b-card>

            <b-card :img-src="domy" img-alt="Card image" img-bottom @click='$router.push("/wspolnoty")'>
              <b-card-text>
                SPRZĄTANIE WSPÓLNOT MIESZKANIOWYCH
              </b-card-text>
            </b-card>

          </div>
        </b-col>
      </b-row>

    </b-container>
    <footer variant='light'>
      Copyright © 2021 SilverDesign.
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data () {
  return {
    domy: require('@/assets/mieszkanko.jpg'),
    office: require('@/assets/office.jpg'),
    kosiara: require('@/assets/kosiara.jpg'),
    lopata: require('@/assets/lopata.jpg'),
    caroseul_props: { height: 410 },
  }
},
}
</script>

<style lang='scss'>
$primary: #AAD8DC;
$dropdown: #B7D8DB;
$dropdown-hover: #A7BFC1;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';

html, body{
  min-height: 100vh; 
  font-size: 1rem;
  .bg-info {
    background-color: $primary;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: black;
    min-height: 100vh;  
    .container{
      min-height: 100%;
    }

    .content{
      min-height: 24vh;
      p{
        padding: 3px;
        text-align: left;
        //text-indent: 1em;
      }
    }

    footer{
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
      margin-top: 50px;
      padding: 5px;

      .zewnetrze{
        margin-top: 150px
      }
    }
  }  

  .navbar{
    border-bottom: 1px rgb(200, 200, 200) solid;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1.8vh;   
    background-color: $primary;
    min-height: 8vh;
    
    img{
      width: 12vh;
    }
    .nav-item{
      border: 1px rgba(0, 0, 0, 0) solid;
      border-radius: 4px;
      @media only screen and (min-width: 768px) {
        margin-left: 25px;
      }
      .nav-link{
        color: rgba(0,0,0,0.9) !important;
      }
      a{
        color: rgba(0,0,0,1);
      }
        &:hover{
          border: 1px rgb(100, 100, 100) solid;
        }
    }

    .navbar-collapse{
      max-width: 95%;
    }

    .dropdown-menu{
      background-color: $dropdown;
      .dropdown-item{
        overflow-wrap: break-word;
        &:hover{
          background-color: $dropdown-hover;
        }
        @media only screen and (max-width: 450px) {
          padding: 10px;
        }
        @media only screen and (max-width: 400px) {
          padding: 5px;
          font-size: 0.9rem;
        }
        @media only screen and (max-width: 370px) {
          padding: 5px;
          font-size: 0.85rem;
        }
        @media only screen and (max-width: 350px) {
          padding: 5px;
          font-size: 0.80rem;
        }
      }
    }
  }  

  #carousel{
    margin-bottom: 25px;
    max-height: 547px;
  }

  .oferta-col{
    display: block;
    .card{
      margin-bottom: 20px;
      font-size: 1.4vh; 
      background-color: $primary;
      cursor: pointer;
      .card-body{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 50px;
      }
      img{
        object-fit: fill;
      }
    }
  }
}
</style>
